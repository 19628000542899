
<app-eezi-header  *ngIf="showMobile"></app-eezi-header>
<div id="header-sticky" class="header-top" *ngIf="!showMobile">
      <div class="row sticky" style="height: 135px;    position: fixed;
      width: 100%;
      z-index: 999999;
     
      width: 100%;"
      [ngClass]="{'scrolled': stick}">
          <div class="hidden-md-down col-md-2 offset-1 pt-1" id="_desktop_logo" routerLink="/" >
              <a routerLink="/">
                  <img class="logo img-responsive" src="../../../assets/img/eezi-logo.png" alt="Medici | Home2" >
                  <p style="font-size: 16px;font-weight: 700;margin-top: 1%;" class="reponsive-name"><span style="color: #0c4575;">EEZI </span> <span style="color: red;">HEALTH</span></p>
              </a>
          </div>
          <div class="position-static hidden-md-down col-7" id="header-menu">
              <div class="container">
                  <div class="row">
                      <div id="_desktop_fieldmegamenu" class="fieldmegamenu">
                          <ul>

                              <li class="root root-1 menu-home horizontal">
                                  <div class="root-item no-description pull-left">

                                      <a routerLink="/" [ngClass]="{'dark':dark}">
                                          <div class="title title_font" >
                                              <span class="title-text">HOME
                                              </span>
                                              
                                          </div>
                                      </a>
                                  </div>
                              <li class="root root-3 menu_1 horizontal">
                                <div class="root-item no-description pull-left">
                                    <a  routerLink="/shop/collection/right/sidebar" [queryParams]="{ type:'medical'}" [ngClass]="{'dark':dark}">
                                        <div class="title title_font"><span class="title-text">Medical Supplies</span><span class="icon-has-sub fa fa-angle-down hidden-sm-down" aria-hidden="true"></span>
                                        </div>
                                    </a>
                                    <div class="dropdown">
                                     <app-menu-items></app-menu-items>
                                    </div>
                                    <!-- <app-menu-items></app-menu-items> -->
                                </div>
                                
                                <ul id="submegamenu-3" class="menu-items col-md-6 col-xs-12 collapse">
                                    <li class="menu-item menu-item-4 depth-1 customcontent menucol-1-3">
                                        <div class="normalized">
                                            <div class="img1"><a href="#"><img class="img-responsive" src="https://apps.digitaldistrict.co.za/medici/modules/fieldmegamenu/images/img1_menu.jpg" alt=""></a></div>
                                        </div>
                                    </li>
                                    <li class="menu-item menu-item-5 depth-1 customcontent menucol-1-3">
                                        <div class="normalized">
                                            <div class="img2"><a href="#"><img class="img-responsive" src="https://apps.digitaldistrict.co.za/medici/modules/fieldmegamenu/images/img2_menu.jpg" alt=""></a></div>
                                        </div>
                                    </li>
                                    <li class="menu-item menu-item-6 depth-1 divider"></li>
                                    <li class="menu-item menu-item-7 depth-1 category menucol-1-3">
                                        <div data-toggle="collapse" data-parent="#submegamenu-3" data-target="#submenu-8" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                            <i class="fa fa-plus add" aria-hidden="true"></i>
                                            <i class="fa fa-minus remove" aria-hidden="true"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open" aria-hidden="true"></i>
                                        </div>
                                        <ul id="submenu-8" class="submenu submenu-depth-2 collapse">
                                            <li class="menu-item menu-item-8 depth-2 category   ">
                                                <div class="title">
                                                    <a href="15-home-brewing.testing"> Needles
                                                    </a>
                                                </div>
                                            </li>

                                            <li class="menu-item menu-item-9 depth-2 category">
                                                <div class="title">
                                                    <a href="16-table-linens.testing"> Syringes
                                                    </a>
                                                </div>
                                            </li>

                                            <li class="menu-item menu-item-10 depth-2 category ">
                                                <div class="title">
                                                    <a href="17-knife-accessories.testing">Cotton Wool
                                                    </a>
                                                </div>
                                            </li>

                                            <li class="menu-item menu-item-11 depth-2 category   ">


                                                <div class="title">
                                                    <a href="18-utensils-gadgets.testing"> Air Management
                                                    </a>
                                                </div>
                                            </li>

                                            <li class="menu-item menu-item-12 depth-2 category   ">
                                                <div class="title">
                                                    <a href="19-coffee.testing"> Gloves
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-13 depth-1 category menucol-1-3  ">









                                        <div data-toggle="collapse" data-parent="#submegamenu-3" data-target="#submenu-14" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                            <i class="fa fa-plus add" aria-hidden="true"></i>
                                            <i class="fa fa-minus remove" aria-hidden="true"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open" aria-hidden="true"></i>
                                        </div>
                                        <ul id="submenu-14" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-14 depth-2 category   ">


                                                <div class="title">
                                                    <a href="20-equipment.testing"> Intravenous
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-15 depth-2 category   ">


                                                <div class="title">
                                                    <a href="15-home-brewing.testing"> Diagnostics
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-16 depth-2 category   ">


                                                <div class="title">
                                                    <a href="17-knife-accessories.testing"> Patient Monitoring
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-17 depth-2 category   ">


                                                <div class="title">
                                                    <a href="19-coffee.testing"> Urinary
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-18 depth-2 category   ">


                                                <div class="title">
                                                    <a href="18-utensils-gadgets.testing"> Consumables
                                                    </a>
                                                </div>




                                            </li>



                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-19 depth-1 customcontent menucol-1-3  ">


                                        <div class="normalized">
                                            <div class="img3"><a href="#"><img class="img-responsive" src="https://apps.digitaldistrict.co.za/medici/modules/fieldmegamenu/images/img3_menu.jpg" alt=""></a></div>
                                        </div>





                                    </li>

                                </ul>
                            </li>
                            <li class="root root-4 menu_2 horizontal">
                                <div class="root-item no-description pull-left">

                                    <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ type:'mobility'}" [ngClass]="{'dark':dark}">
                                        <div class="title title_font"><span class="title-text">Mobility</span>
                                        </div>
                                    </a>
                                    <!-- <div class="dropdown">
                                      <app-range-items></app-range-items>
                                     </div> -->
                                </div>
                                <!-- <div data-toggle="collapse" data-parent="#_mobile_fieldmegamenu" data-target="#submegamenu-4" class="collapsed-menu collapsed hidden-lg-up pull-right" aria-expanded="false" role="button">
                                    <i class="fa fa-plus add" aria-hidden="true"></i>
                                    <i class="fa fa-minus remove" aria-hidden="true"></i>
                                </div> -->
                                <ul id="submegamenu-4" class="menu-items col-md-4 col-xs-12 collapse">



                                    <li class="menu-item menu-item-20 depth-1 category menucol-1-2  ">








                                        <div data-toggle="collapse" data-parent="#submegamenu-4" data-target="#submenu-21" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                            <i class="fa fa-plus add" aria-hidden="true"></i>
                                            <i class="fa fa-minus remove" aria-hidden="true"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open" aria-hidden="true"></i>
                                        </div>
                                        <ul id="submenu-21" class="submenu submenu-depth-2 collapse">

                                            <li class="menu-item menu-item-21 depth-2 category   ">


                                                <div class="title">
                                                    <a href="15-home-brewing.testing"> Plasterman
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-22 depth-2 category   ">


                                                <div class="title">
                                                    <a href="16-table-linens.testing"> Nebu Mist
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-23 depth-2 category   ">


                                                <div class="title">
                                                    <a href="17-knife-accessories.testing"> MiDrop
                                                    </a>
                                                </div>




                                            </li>




                                            <li class="menu-item menu-item-24 depth-2 category   ">


                                                <div class="title">
                                                    <a href="18-utensils-gadgets.testing"> Aqua Sanz
                                                    </a>
                                                </div>




                                            </li>






                                        </ul>
                                    </li>

                                    <li class="menu-item menu-item-26 depth-1 category menucol-1-2  ">


                                    







                                        <div data-toggle="collapse" data-parent="#submegamenu-4" data-target="#submenu-27" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                            <i class="fa fa-plus add" aria-hidden="true"></i>
                                            <i class="fa fa-minus remove" aria-hidden="true"></i>
                                        </div>
                                        <div class="has_icon hidden-sm-down pull-right">
                                            <i class="fa open" aria-hidden="true"></i>
                                        </div>
                                        <ul id="submenu-27" class="submenu submenu-depth-2 collapse">
                                            <li class="menu-item menu-item-27 depth-2 category   ">
                                                <div class="title">
                                                    <a href="20-equipment.testing"> Preg N Care
                                                    </a>
                                                </div>
                                            </li>
                                            <li class="menu-item menu-item-28 depth-2 category">
                                                <div class="title">
                                                    <a href="15-home-brewing.testing"> Healthease
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>

                            </li>
                            


                              <li class="root root-4 menu_2 horizontal">
                                  <div class="root-item no-description pull-left">

                                      <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ type:'workwear'}" [ngClass]="{'dark':dark}">
                                          <div class="title title_font"><span class="title-text">Workwear</span><span class="icon-has-sub fa fa-angle-down hidden-sm-down" aria-hidden="true"></span>
                                          </div>
                                      </a>
                                      <div class="dropdown">
                                        <app-range-items></app-range-items>
                                       </div>
                                  </div>
                                  <div data-toggle="collapse" data-parent="#_mobile_fieldmegamenu" data-target="#submegamenu-4" class="collapsed-menu collapsed hidden-lg-up pull-right" aria-expanded="false" role="button">
                                      <i class="fa fa-plus add" aria-hidden="true"></i>
                                      <i class="fa fa-minus remove" aria-hidden="true"></i>
                                  </div>
                                  <ul id="submegamenu-4" class="menu-items col-md-4 col-xs-12 collapse">



                                      <li class="menu-item menu-item-20 depth-1 category menucol-1-2  ">








                                          <div data-toggle="collapse" data-parent="#submegamenu-4" data-target="#submenu-21" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                              <i class="fa fa-plus add" aria-hidden="true"></i>
                                              <i class="fa fa-minus remove" aria-hidden="true"></i>
                                          </div>
                                          <div class="has_icon hidden-sm-down pull-right">
                                              <i class="fa open" aria-hidden="true"></i>
                                          </div>
                                          <ul id="submenu-21" class="submenu submenu-depth-2 collapse">

                                              <li class="menu-item menu-item-21 depth-2 category   ">


                                                  <div class="title">
                                                      <a href="15-home-brewing.testing"> Plasterman
                                                      </a>
                                                  </div>




                                              </li>




                                              <li class="menu-item menu-item-22 depth-2 category   ">


                                                  <div class="title">
                                                      <a href="16-table-linens.testing"> Nebu Mist
                                                      </a>
                                                  </div>




                                              </li>




                                              <li class="menu-item menu-item-23 depth-2 category   ">


                                                  <div class="title">
                                                      <a href="17-knife-accessories.testing"> MiDrop
                                                      </a>
                                                  </div>




                                              </li>




                                              <li class="menu-item menu-item-24 depth-2 category   ">


                                                  <div class="title">
                                                      <a href="18-utensils-gadgets.testing"> Aqua Sanz
                                                      </a>
                                                  </div>




                                              </li>






                                          </ul>
                                      </li>

                                      <li class="menu-item menu-item-26 depth-1 category menucol-1-2  ">


                                      







                                          <div data-toggle="collapse" data-parent="#submegamenu-4" data-target="#submenu-27" class="collapsed-menu collapsed hidden-sm-up pull-right" aria-expanded="false" role="button">
                                              <i class="fa fa-plus add" aria-hidden="true"></i>
                                              <i class="fa fa-minus remove" aria-hidden="true"></i>
                                          </div>
                                          <div class="has_icon hidden-sm-down pull-right">
                                              <i class="fa open" aria-hidden="true"></i>
                                          </div>
                                          <ul id="submenu-27" class="submenu submenu-depth-2 collapse">
                                              <li class="menu-item menu-item-27 depth-2 category   ">
                                                  <div class="title">
                                                      <a href="20-equipment.testing"> Preg N Care
                                                      </a>
                                                  </div>
                                              </li>
                                              <li class="menu-item menu-item-28 depth-2 category">
                                                  <div class="title">
                                                      <a href="15-home-brewing.testing"> Healthease
                                                      </a>
                                                  </div>
                                              </li>
                                          </ul>
                                      </li>

                                  </ul>

                              </li>

                            


                              <li class="root root-5 ">
                                  <div class="root-item no-description pull-left">

                                      <a  routerLink="/" [queryParams]="{'page':'about'}"  fragment="about-us" [ngClass]="{'dark':dark}">
                                          <div class="title title_font"><span class="title-text">About Us</span></div>
                                      </a>
                                  </div>

                              </li>

                              <li class="root root-7 ">
                                  <div class="root-item no-description pull-left">

                                      <a routerLink="/" [queryParams]="{'page':'about'}"  [ngClass]="{'dark':dark}">
                                          <div class="title title_font"><span class="title-text">CONTACT</span></div>
                                      </a>
                                  </div>

                              </li>

                          </ul>
                      </div>
                  </div>
              </div>

              <div class="clearfix"></div>
          </div>
          <div class="col-3" style="display: flex; justify-content: end;">
            <div>
                <!-- <app-settings></app-settings> -->
              </div>
        </div>
      </div>
</div>

<div style="display: flex; align-items: center" *ngIf="showMobile">
    <div class="hamburger-menu col-4">
        <div class="hamburger-icon" (click)="toggleMenu()">
        <span></span>
        <span></span>
        <span></span>
        </div>
        <ul class="menu-items" [ngClass]="{ 'menu-open': isMenuOpen }">
            <li class="nav-item">
                <a (click)="navigateTo('/')" class="nav-link"> HOME </a>
            </li>
            <li class="nav-item">
                <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ type:'medical'}" class="nav-link"> MEDICAL SUPPLIES </a>
            </li>
            <li class="nav-item" (click)="this.isMenuOpen = false;">
                <a routerLink="/shop/collection/right/sidebar" [queryParams]="{ type:'workwear'}" class="nav-link"> WORKWEAR </a>
            </li>
            <li class="nav-item"  (click)="this.isMenuOpen = false;">
                <a  routerLink="/" [queryParams]="{'page':'about'}"  class="nav-link"> ABOUT US </a>
            </li>
            <li class="nav-item"  (click)="this.isMenuOpen = false;">
                <a routerLink="/" [queryParams]="{'page':'about'}" class="nav-link"> CONTACT </a>
            </li>
        </ul>
    </div>
    <div class="col-3 offset-1 pt-1"  routerLink="/">
        <img src="../../../assets/img/eezi-logo.png" style="height: 80px; margin-left: 8%;">
        <p style="font-size: 14px;font-weight: 700;margin-top: 1%;" class="reponsive-name"><span style="color: #0c4575;">EEZI </span> <span style="color: red;">HEALTH</span></p>
        
    </div>
    <div class="col-4" style="display: flex; justify-content: end;">
        <!-- <app-settings></app-settings> -->
    </div>
</div>
<!-- <div class="row header-top"  id="header-sticky" >
    
</div> -->